import React from 'react';

function App() {
    return (
        <div className='App' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <h1 style={{ fontSize: '48px', textAlign: 'center', fontFamily: 'Montserrat', fontWeight: 'normal' }}>STRANICA U IZRADI...</h1>
        </div>
    );
}

export default App;
